@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,400;1,700&display=swap');

$background_color: #f1faee;
$header_color: #3b5d8c;

* {
	font-family: 'Roboto';
  margin: 0;
	padding: 0;
	user-select: none;
	text-align: center;
	-webkit-user-drag:none;
	-moz-user-drag:none;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
	background-color: $background_color;
	overflow: hidden;
}

header {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 100vw;
	background-color: $header_color;
	color: $background_color;

	.headerGroup {
		display: flex;
		flex-direction: column;
		width: 100%;

		&:first-child {
			border-right: solid $background_color 0.15em;
		}

		&-top {
			font-size: 1em;
			margin-top: 0.3em;
			margin-bottom: 0.3em;
		}

		&-bottom {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			align-items: center;
			height: 100%;
			font-size: 1em;
			margin-bottom: 0.5em;

			> p {
				padding: 0.45em;
			}

			&-button-wrapper {
				display: flex;
				justify-content: center;
				max-width: 6em;
				flex-basis: 100%;

				> .button {
					flex-basis: 100%;
					padding: 0.3em;
					border: solid $background_color 0.15em;
					text-align: center;

					&:hover, &:focus {
						background-color: $background_color;
						color: $header_color;
						transition-duration: 0.2s;
						cursor: pointer;
					}
				}
			}
			
		}
	}
}


.maze {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 3em;
	margin-bottom: 3em;

	&-grid {
		display: flex;
		flex-direction: row;
		
		&-column {
			display: flex;
			flex-direction: column;

			> img {
				background-color: #a8dadc;

				&.selectable {
					cursor: pointer;
				}

				&.stacked {
					background-color: #457b9d;
				}

				&.head {
					background-color: #ffe599;
				}

				&.start {
					background-color: #86b86f;
				}

				&.goal {
					background-color: #e06666;
				}

				&.start.goal {
					background-color: #8e7cc3;
				}
			}
		}
	}
}

.settingsWindow {
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	&-bg {
		z-index: 1;
		background-color: rgba(0, 0, 0, 0.6);
		width: 100%;
		height: 100%;
		position: fixed;

		&:hover  {
			cursor: pointer;
		}
	}
	

	&-main {
		position: absolute;
		display: flex;
		flex-direction: column;
		z-index: 2;
		border: solid $background_color 5px;
		background-color: $header_color;
		max-width: 20em;
		max-height: 20em;
		border-radius: 1em;
		padding: 1em;
		color: $background_color;
		font-size: 2em;
		gap: 1em;

		&-setting_container {
			display: flex;
			flex-direction: row;
			width: 100%;
			height: 100%;
			gap: 2em;

			> label {
				text-align: left;
			}
			
			> input {
				margin-left: auto;
				background-color: rgba(0, 0, 0, 0.2);
				border: solid $header_color 2px;
				color: $background_color;
				font-size: 1em;
				box-sizing: border-box;

				&:focus-visible {
					outline: none;
					border: solid $background_color 2px;
				}

				&::placeholder {
					color: rgba($background_color, 0.6);
				}
			}
		}

		&:last-child {
			margin: auto;
		}
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 800px) {
	header {
		flex-direction: column;

		.headerGroup {

			&:first-child {
				border-right: none;
				border-bottom: solid $background_color 0.15em;
			}
		}
	}
}

@media screen and (max-width: 500px) {
	.settingsWindow {

		&-main {
			font-size: 1em;

			&-setting_container {
				gap: 1em;
			}
		}
	}
}